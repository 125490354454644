@import "./../mixins/mixins";


.graphic-background-bg-illustration-cloudservers{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .graphic-background{
        &-left{
            margin-right: auto;
            min-width: max-content;
            @supports (-webkit-hyphens:none){
                height: intrinsic;
            }
            @media (max-width: 1920px){
                margin-left: calc((((1920px - 100vw)* -1) / 2));
            }
        }
        &-right{
            margin-left: auto;
            min-width: max-content;
            @supports (-webkit-hyphens:none){
                height: intrinsic;
            }
            @media (max-width: 1920px){
               margin-right: calc((((1920px - 100vw)* -1) / 2));
            }
        }
    }
    &[data-animation-css]{
        &:not(.is-animated){
            [data-top-left-animation],
            [data-top-right-animation],
            [data-animation-path],
            [data-animation-cube] {
                animation: unset!important
            }
        }
        &.is-paused{
            [data-animation-cube],
            [data-animation-coin],
            [data-animation-dot],
            [data-animation-vertical-loop-sm],
            [data-animation-vertical-loop],
            [data-animation-up-down],
            [data-animation-down-up] {
                // animation: unset!important
                animation-play-state: paused;
            }
        }
        [data-top-left-animation],
        [data-top-right-animation],
        [data-animation-cube] {
            opacity: 0;
        }
        &.is-animated{ 
            [data-top-left-animation]{
                animation-name: showElementTopLeft-cloudservers;
                animation-duration: 1s;
                animation-timing-function: cubic-bezier(0, 0, 0, 1);
                animation-fill-mode: forwards;
            }
            [data-top-right-animation]{
                animation-name: showElementTopRight-cloudservers;
                animation-duration: 1s;
                animation-timing-function: cubic-bezier(0, 0, 0, 1);
                animation-fill-mode: forwards;
                &[data-animation-type-bottom-1]{
                    animation-name: sectionBackgroundFadeBottom1-cloudservers;
                }
                &[data-animation-type-bottom-2]{
                    animation-name: sectionBackgroundFadeBottom2-cloudservers;
                }
                &[data-animation-type-bottom-3]{
                    animation-name: sectionBackgroundFadeBottom3-cloudservers;
                }
                &[data-animation-type-bottom-4]{
                    animation-name: sectionBackgroundFadeBottom4-cloudservers;
                }
            }
            [data-animation-delay-1]{
                animation-delay: 0s;
            }
            [data-animation-delay-2]{
                animation-delay: 0.12s;
            }
            [data-animation-delay-3]{ 
                animation-delay: 0.24s;
            }
            [data-animation-delay-5]{
                animation-delay: 1.96s;
            }
            [data-animation-delay-6]{
                animation-delay: 1.56s;
            }
            [data-animation-delay-7]{
                animation-delay: 2.36s;
            }
            [data-animation-delay-8]{
                animation-delay: 1.24s;
            }
            [data-animation-duration-0]{
                animation-duration: 0.64s;
            }
            [data-animation-delay-server-1]{ 
                animation-delay: 0.24s;
            }
            [data-animation-delay-server-2]{ 
                animation-delay: 0.32s;
            }
            [data-animation-delay-server-3]{ 
                animation-delay: 0.40s;
            }
            [data-animation-delay-server-4]{ 
                animation-delay: 0.48s;
            }
            [data-animation-cube]{
                will-change: transform, opacity;
                animation-name: cubeLoop-cloudservers;
                animation-duration: 2.20s;
                animation-delay: 1.08s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
                &:nth-child(1){
                    animation-delay: 0.32s;
                }
                &:nth-child(2){
                    animation-delay: 0.96s;
                }
                &:nth-child(3){
                    animation-delay: 1.24s;
                }
                &[data-animation-cube-1]{
                    --cubeStart: 30px;
                    --cubeGap: -60px;
                }
                &[data-animation-cube-2]{
                    --cubeStart: 45px;
                    --cubeGap: -45px;
                }
                &[data-animation-cube-3]{
                    --cubeStart: 80px;
                    --cubeGap: 10px;
                }
                &[data-animation-cube-sm-1]{
                    --cubeStart: 60px;
                    --cubeGap: -25px;
                }
                &[data-animation-cube-sm-2]{
                    --cubeStart: 50px;
                    --cubeGap: -35px;
                }
                &[data-animation-cube-sm-3]{
                    --cubeStart: 50px;
                    --cubeGap: -35px;
                }
            }
            [data-animation-path]{
                will-change: transform, opacity;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
                &[data-animation-path-1]{
                    animation-name: pathAnimation1-cloudservers-side;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-2]{
                    animation-name: pathAnimation2-cloudservers-side;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-3]{
                    animation-name: pathAnimation3-cloudservers-side;
                    animation-duration: 1.5s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-4]{
                    animation-name: pathAnimation4-cloudservers-side;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-5]{
                    animation-name: pathAnimation5-cloudservers-side;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-6]{
                    animation-name: pathAnimation6-cloudservers-side;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-7]{
                    animation-name: pathAnimation7-cloudservers-side;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-8]{
                    animation-name: pathAnimation8-cloudservers-side;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-9]{
                    animation-name: pathAnimation9-cloudservers-side;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-10]{
                    animation-name: pathAnimation10-cloudservers-side;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
                &[data-animation-path-11]{
                    animation-name: pathAnimation11-cloudservers-side;
                    animation-duration: 1s;
                    animation-delay: 2.08s;
                }
            }
        }
    }
    @keyframes pathAnimation1-cloudservers-side {from {stroke-dashoffset: 144;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation2-cloudservers-side {from {stroke-dashoffset: 312;}to{stroke-dashoffset: 0;}}

    @keyframes pathAnimation3-cloudservers-side {from {stroke-dashoffset: 262;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation4-cloudservers-side {from {stroke-dashoffset: 180;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation5-cloudservers-side {from {stroke-dashoffset: 338;}to{stroke-dashoffset: 0;}}


    @keyframes pathAnimation6-cloudservers-side {from {stroke-dashoffset: 62;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation7-cloudservers-side {from {stroke-dashoffset: 72;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation8-cloudservers-side {from {stroke-dashoffset: -68;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation9-cloudservers-side {from {stroke-dashoffset: 53;}to{stroke-dashoffset: 0;}}

    @keyframes pathAnimation10-cloudservers-side {from {stroke-dashoffset: 115;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation11-cloudservers-side {from {stroke-dashoffset: 72;}to{stroke-dashoffset: 0;}}




    @keyframes sectionBackgroundFadeBottom1-cloudservers {
        0%{
            transform: translate3d(0px, -16px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    @keyframes sectionBackgroundFadeBottom2-cloudservers {
        0%{
            transform: translate3d(0px, -32px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    @keyframes sectionBackgroundFadeBottom3-cloudservers {
        0%{
            transform: translate3d(0px, -48px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }
    @keyframes sectionBackgroundFadeBottom4-cloudservers {
        0%{
            transform: translate3d(0px, -56px, 0px); 
            opacity: 0;
        }
        48%{
            opacity: 1;
        }
        100%{
            transform: translate3d(0px, 0px, 0px);
            opacity: 1;
        }
    }

    @keyframes showElementTopLeft-cloudservers {
        from {
            transform: translate3d(52px,30px, 0px);
            opacity: 0;
        }
        to {
            transform: translate3d(0,0, 0px);
            opacity: 1;
        }
    }
    @keyframes showElementTopRight-cloudservers {
        from {
            transform: translate3d(-52px,30px, 0px);
            opacity: 0;
        }
        to {
            transform: translate3d(0,0, 0px);
            opacity: 1;
        }
    }
    @keyframes cubeLoop-cloudservers {
        0% {
            transform: translate3d(0px, var(--cubeStart), 0px);
            opacity: 0
        }
    
        10%,60% {
            opacity: 1
        }
    
        to {
            transform: translate3d(0px, var(--cubeGap), 0px);
            opacity: 0
        }
    }
}